import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import { Row, Col, Card, Button } from "react-bootstrap"

const ArticlePreview = ({ title, date, excerpt, slug, img, featured = false }) => {
    return (
        <div className="mt-3 mb-3">
            <Card>
            <Card.Img variant="top" src={img} />
                {featured &&
                    <Card.Header as="h5">Featured</Card.Header>
                }
                <Card.Body>
                    <Card.Title>{title}<br></br><span className="h6">{date}</span></Card.Title>
                    <Card.Text>
                        {excerpt}
                    </Card.Text>
                    <Link to={slug} className="link-no-style">

                        <Button variant="primary">Continue Reading</Button>
                    </Link>
                </Card.Body>
            </Card>
        </div>

    )
}
export default ArticlePreview