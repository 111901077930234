import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticlePreview from "../components/articlePreview"
import { Row, Col, Container } from "react-bootstrap"

const AllPosts = ({ data }) => {
  console.log(data)
  return (
    <Layout pageInfo={{ pageName: "all-posts" }}>
      <SEO title="Posts" />
      <Row >
        <Col>
          <Container className="mt-4 ">
            <h2>Posts:</h2>
            <hr></hr>
            {data.allFile.edges.map(row => (
              <ArticlePreview
                key={row.node.childMarkdownRemark.frontmatter.title}
                title={row.node.childMarkdownRemark.frontmatter.title}
                date={row.node.childMarkdownRemark.frontmatter.date}
                excerpt={row.node.childMarkdownRemark.excerpt}
                img={row.node.childMarkdownRemark.frontmatter.featuredImage.childImageSharp.fluid.src}
                slug={row.node.childMarkdownRemark.fields.slug}
              ></ArticlePreview>
            ))}
          </Container>
        </Col>
      </Row>
    </Layout>
  )
}
export const query = graphql`
query {
    allFile(filter: {relativeDirectory: {eq: "posts"}}, sort: {order: DESC, fields: childMarkdownRemark___frontmatter___date}) {
      edges {
        node {
          id
          name
          childMarkdownRemark {
            id
            excerpt
            frontmatter {
              date(formatString: "DD MMMM, YYYY")
              title
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  }
`
export default AllPosts
